import { jsx as _jsx } from "react/jsx-runtime";
import React, { useLayoutEffect } from 'react';
import DefaultEvents from './utils/DefaultEvents';
const ExtensionComponent = ({ id, extensionProps, ...other }) => {
    useLayoutEffect(() => {
        extensionProps.eventMgr.emit(DefaultEvents.ON_PILET_INITIALIZED, { id });
        console.info("layout rendered: " + id);
    });
    const props = { ...extensionProps.props, ...other };
    return (_jsx(extensionProps.component, { ...props, children: extensionProps.children }));
};
export default ExtensionComponent;
