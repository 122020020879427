import DefaultEvents from "../utils/DefaultEvents";
export default class PiletLoadTracker {
    constructor({ eventMgr, initialPage }) {
        this.piletForPage = {};
        this.initializedPilets = [];
        this.currentPage = initialPage;
        this.eventMgr = eventMgr;
        eventMgr.on(DefaultEvents.ON_PILET_ADDED_TO_PAGE, this.onAddPiletToPage.bind(this));
        eventMgr.on(DefaultEvents.ON_PILET_INITIALIZED, this.onPiletInitialized.bind(this));
        eventMgr.on(DefaultEvents.PAGE_CHANGE, this.onPageChange.bind(this));
    }
    onAddPiletToPage({ id, page }) {
        if (!this.piletForPage[page]) {
            this.piletForPage[page] = [];
        }
        this.piletForPage[page].push(id);
    }
    onPageChange({ route }) {
        this.initializedPilets = [];
        this.currentPage = route;
    }
    onPiletInitialized(id) {
        this.initializedPilets.push(id);
        if (this.piletForPage[this.currentPage]?.length === this.initializedPilets.length) {
            console.info("**************** ALL PILETS FOR THIS PAGE INITIALIZED *******************");
            this.eventMgr.emit(DefaultEvents.ON_ALL_PILETS_INITIALIZED);
        }
    }
}
