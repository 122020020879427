var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _BroadcastDispatcher_instances, _BroadcastDispatcher_onBroadcast, _BroadcastDispatcher_getEndpoint;
import AbstractDispatcher from "./AbstractDispatcher";
import { DefaultEndpointTypes } from "@olive/oli-types";
import { invoke, GLOBAL_CHANNEL } from "@olive/broadcast-util";
class BroadcastDispatcher extends AbstractDispatcher {
    constructor(wiringManager, connectivityManager) {
        super(wiringManager);
        _BroadcastDispatcher_instances.add(this);
        this.connectivityManager = connectivityManager;
        this.broadcastChannel = GLOBAL_CHANNEL;
        this.broadcastChannel.onmessage = __classPrivateFieldGet(this, _BroadcastDispatcher_instances, "m", _BroadcastDispatcher_onBroadcast).bind(this);
    }
    canDispatch(props) {
        const endpoint = !props.endpoint ? __classPrivateFieldGet(this, _BroadcastDispatcher_instances, "m", _BroadcastDispatcher_getEndpoint).call(this, props.id) : props.endpoint;
        if (!endpoint) {
            return false;
        }
        const type = endpoint.endpoint.type;
        return type === DefaultEndpointTypes.CLIENT_BROADCAST;
    }
    async dispatch(props) {
        const inputData = props.input;
        const message = invoke({
            topic: inputData.topic,
            payload: inputData.payload
        }, props.id);
        return message;
    }
}
_BroadcastDispatcher_instances = new WeakSet(), _BroadcastDispatcher_onBroadcast = async function _BroadcastDispatcher_onBroadcast(message) {
    console.log("#onBroadcast", message);
    const envelope = message.data;
    console.log("event to dispatch", envelope);
    const allWirings = this.wiringManager.getAll();
    const connectors = Object.keys(allWirings).map(key => allWirings[key]).filter(wiring => wiring.type === "connectorInstance");
    const matchingConnectors = connectors.filter(connector => connector.source.id === envelope.endpointID);
    console.log("matchingConnectors", matchingConnectors);
    const dispatchers = matchingConnectors.map(connector => this.connectivityManager.invoke({
        id: connector.destination.instanceID,
        input: envelope.payload
    }));
    const result = await Promise.all(dispatchers);
    console.log("dispatched: ", result);
}, _BroadcastDispatcher_getEndpoint = function _BroadcastDispatcher_getEndpoint(id) {
    if (!id) {
        return null;
    }
    return this.wiringManager.get(id);
};
export default BroadcastDispatcher;
