export default class WiringManager {
    constructor(wirings) {
        this.wirings = wirings;
    }
    get(id) {
        if (!this.wirings[id]) {
            console.error("Wiring not found: " + id);
        }
        return this.wirings[id];
    }
    getAll() {
        return this.wirings;
    }
}
