import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ExtensionSlot } from 'piral-core';
import React from 'react';
const createPage = ({ pageKey, layoutType = "" }) => {
    return (props) => {
        switch (layoutType) {
            case "single":
                return (_jsx("div", { children: _jsx(ExtensionSlot, { name: `${pageKey}-default`, params: props }) }));
            case "wizard":
                return (_jsxs("div", { children: [_jsx(ExtensionSlot, { name: `${pageKey}-stepper`, params: props }), _jsx(ExtensionSlot, { name: `${pageKey}-content`, params: props })] }));
            case "chat":
                return (_jsxs("div", { style: {
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                    }, children: [_jsx("div", { style: {
                                flex: 1,
                                overflowY: "auto",
                            }, children: _jsx(ExtensionSlot, { name: `${pageKey}-content`, params: props }) }), _jsx(ExtensionSlot, { name: `${pageKey}-input`, params: props })] }));
            case "border":
                return (_jsx("div", { style: { display: "table", padding: 20, height: '100%' }, children: _jsxs("div", { style: { display: "table-row", height: '100%' }, children: [_jsxs("div", { style: { display: "table-cell", width: 200, height: '100%' }, children: [" ", _jsx(ExtensionSlot, { name: `${pageKey}-east`, params: props })] }), _jsxs("div", { style: { display: "table-cell", width: '100%', height: '100%' }, children: [" ", _jsx(ExtensionSlot, { name: `${pageKey}-west`, params: props })] }), _jsxs("div", { style: { display: "table-cell", width: 200, height: '100%' }, children: [" ", _jsx(ExtensionSlot, { name: pageKey, params: props })] })] }) }));
            default:
                return (_jsxs("div", { children: ["Layout type ", layoutType, " not supported"] }));
        }
    };
};
export default {
    createPage,
};
