class Environment {
    constructor() {
        this.envVars = {};
    }
    set(key, value) {
        this.envVars[key] = value;
    }
    get(key) {
        return this.envVars[key];
    }
}
export var ENV_VARS;
(function (ENV_VARS) {
    ENV_VARS["CONFIG_ID"] = "configID";
})(ENV_VARS || (ENV_VARS = {}));
export default new Environment();
