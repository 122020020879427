var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _WindowDispatcher_instances, _WindowDispatcher_onMessage, _WindowDispatcher_isRegisterOriginMessage, _WindowDispatcher_registerOriginMessage, _WindowDispatcher_getEndpoint;
import AbstractDispatcher from "./AbstractDispatcher";
import { DefaultEndpointTypes } from "@olive/oli-types";
class WindowDispatcher extends AbstractDispatcher {
    constructor(wiringManager, connectivityManager) {
        super(wiringManager);
        _WindowDispatcher_instances.add(this);
        this.connectivityManager = connectivityManager;
        this.origins = {};
        window.addEventListener('message', __classPrivateFieldGet(this, _WindowDispatcher_instances, "m", _WindowDispatcher_onMessage).bind(this));
    }
    canDispatch(props) {
        const endpoint = !props.endpoint ? __classPrivateFieldGet(this, _WindowDispatcher_instances, "m", _WindowDispatcher_getEndpoint).call(this, props.id) : props.endpoint;
        if (!endpoint) {
            return false;
        }
        const type = endpoint.endpoint.type;
        return type === DefaultEndpointTypes.WINDOW_MESSAGE;
    }
    async dispatch(props) {
        const endpoint = __classPrivateFieldGet(this, _WindowDispatcher_instances, "m", _WindowDispatcher_getEndpoint).call(this, props.id);
        if (!endpoint) {
            console.warn("Endpoint not found: " + props.id);
            return;
        }
        const origin = endpoint.endpoint.windowmessage.origin;
        const originEntry = this.origins[origin];
        if (!originEntry) {
            console.error("Tried to dispatch message to unknown origin: " + origin);
        }
        const message = endpoint.endpoint.windowmessage.topic;
        // TODO need something better to combine input and messgae
        // we can not always send the same format / schema, needs to be configurable
        const envelope = {
            message: message,
            input: props.input
        };
        //@ts-ignore
        originEntry.win.postMessage(envelope, originEntry.origin);
        return props.input;
    }
}
_WindowDispatcher_instances = new WeakSet(), _WindowDispatcher_onMessage = async function _WindowDispatcher_onMessage(message) {
    console.log("#onMessage", message);
    const envelope = message.data;
    if (__classPrivateFieldGet(this, _WindowDispatcher_instances, "m", _WindowDispatcher_isRegisterOriginMessage).call(this, envelope)) {
        __classPrivateFieldGet(this, _WindowDispatcher_instances, "m", _WindowDispatcher_registerOriginMessage).call(this, message);
        return;
    }
    console.log("event to dispatch", envelope);
    const allWirings = this.wiringManager.getAll();
    const connectors = Object.keys(allWirings).map(key => allWirings[key]).filter(wiring => wiring.type === "connectorInstance");
    const matchingConnectors = connectors.filter(connector => connector.source.id === envelope.endpointID);
    console.log("matchingConnectors", matchingConnectors);
    const dispatchers = matchingConnectors.map(connector => this.connectivityManager.invoke({
        id: connector.destination.instanceID,
        input: envelope.payload
    }));
    const result = await Promise.all(dispatchers);
    console.log("dispatched: ", result);
}, _WindowDispatcher_isRegisterOriginMessage = function _WindowDispatcher_isRegisterOriginMessage(envelope) {
    return envelope.topic === "oliveRegisterOrigin";
}, _WindowDispatcher_registerOriginMessage = function _WindowDispatcher_registerOriginMessage(message) {
    console.info("registering new window origin");
    const envelope = message.data;
    const payload = envelope.payload;
    if (payload.origin && payload.id) {
        this.origins[payload.id] = {
            origin: payload.origin,
            win: message.source
        };
    }
    else {
        console.error("Missing origin or window in register origin message: ", payload);
    }
}, _WindowDispatcher_getEndpoint = function _WindowDispatcher_getEndpoint(id) {
    if (!id) {
        return null;
    }
    return this.wiringManager.get(id);
};
export default WindowDispatcher;
