import { DefaultEndpointTypes, } from "@olive/oli-types";
import { isConnectorInstance, } from "../utils/connectivityUtil";
export default class ConnectivityManager {
    constructor(props) {
        this.wiringManager = props.wiringManager;
        this.eventManager = props.eventManager;
        this.dispatchers = {};
        this.setupEventListeners();
    }
    setupEventListeners() {
        const wirings = this.wiringManager.getAll();
        for (let key in wirings) {
            const endpoint = wirings[key];
            // we only care about connector instances here, not about connector definitions because
            // client side events can only exist in connector instances
            if (isConnectorInstance(endpoint)) {
                const connectorInstance = endpoint;
                const source = connectorInstance.source;
                if (!source) {
                    continue;
                }
                const sourceEndpointID = typeof source === "string" ? source : source.instanceID;
                const sourceEndpoint = wirings[sourceEndpointID];
                let destination;
                let destinationEndpointID;
                if (!sourceEndpoint) {
                    //FBA: Discuss this with olive team, we know make an event after every api call and people can register onto it
                    const strippedText = sourceEndpointID.replace(/^onAfter_(.*)/, "$1");
                    const sourceEndpoint = wirings[strippedText];
                    if (!sourceEndpoint)
                        continue;
                    destination = connectorInstance.destination;
                    destinationEndpointID =
                        typeof destination === "string"
                            ? destination
                            : destination.instanceID;
                    this.eventManager.on(sourceEndpointID, this.onEvent.bind(this, destination));
                    continue;
                }
                if (sourceEndpoint?.endpoint?.type === DefaultEndpointTypes.EVENT) {
                    destination = connectorInstance.destination;
                    destinationEndpointID =
                        typeof destination === "string"
                            ? destination
                            : destination.instanceID;
                    const eventTopic = sourceEndpoint.endpoint.event.topic;
                    this.eventManager.on(eventTopic, this.onEvent.bind(this, {
                        id: destinationEndpointID,
                        endpoint: destination,
                        input: connectorInstance.input,
                    }));
                }
            }
            // if ("type" in endpoint && endpoint.type === TYPE.CONNECTOR) {
            //   // @ts-ignore we know it's a connector instance because of the check before
            //   const connector = endpoint as ConnectorInstance;
            //   const source = connector.source;
            //   const sourceEndpointID =
            //     typeof source === "string" ? source : source.id;
            //   const sourceEndpoint = wirings[sourceEndpointID] as Endpoint;
            //   if (sourceEndpoint.endpoint.type === DefaultEndpointTypes.EVENT) {
            //     const destination = connector.destination;
            //     const destinationEndpointID = typeof destination === "string" ? destination : destination.id;
            //     const eventTopic = (sourceEndpoint as EventEndpoint).endpoint.event
            //       .topic;
            //     this.eventManager.on(
            //       eventTopic,
            //       this.onEvent.bind(this, destinationEndpointID)
            //     );
            //   }
            // }
        }
    }
    async onEvent({ id, endpoint, input: connectorInput = {}, }, params) {
        let input = {};
        if (params instanceof FormData) {
            input.formData = params;
        }
        else if (typeof params === 'object' && params !== null) {
            input = { ...params };
        }
        input = { ...input, ...connectorInput };
        if (endpoint?.input) {
            input = { ...input, ...endpoint.input };
        }
        const result = await this.invoke({
            id,
            input,
        });
        return result;
    }
    async invoke(props) {
        for (let key in this.dispatchers) {
            const dispatcher = this.dispatchers[key];
            if (dispatcher.canDispatch({ id: props.id })) {
                return await dispatcher.dispatch(props);
            }
        }
        console.error("No dispatcher found that can dispatch " + props.id);
        throw "No dispatcher found that can dispatch " + props.id;
    }
    registerDispatcher(id, dispatcher) {
        if (this.dispatchers[id]) {
            console.warn("Dispatcher with id " + id + " already registered");
        }
        this.dispatchers[id] = dispatcher;
    }
}
