export default class APIManager {
    constructor() {
        this.apis = {};
    }
    add(key, fn) {
        if (typeof (fn) !== "function") {
            console.error("Added non-function to APIManager with key " + key);
        }
        console.info("Registered API " + key, fn);
        this.apis[key] = fn;
    }
    get(key) {
        return this.apis[key];
    }
}
