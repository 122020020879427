export function isConnectorInstance(instance) {
    return instance.type === 'connectorInstance';
}
export function isEndpointInstance(instance) {
    return instance.type === 'endpointInstance';
}
export function isConnectorDefinition(definition) {
    return definition.type === 'connector';
}
export function isEndpointDefinition(definition) {
    return definition.type === 'endpoint';
}
export function isWiringFragment(fragment) {
    return fragment.type === 'wiring';
}
